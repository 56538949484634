
/* Remove focus outline for all SVG elements */
svg:focus {
  outline: none;
}

/* Remove outline for all paths (if needed) */
path:focus {
  outline: none;
}
.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}
@keyframes bounce {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -55%) scale(1.1);
  }
}
